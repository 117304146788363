import { useState } from "react";
import { Storage } from '@ionic/storage';
import {
    IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent,
    IonFab, IonFabButton, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonPage,
    IonRange, IonRow, IonToggle
} from "@ionic/react";
import { settings, close } from 'ionicons/icons';


import TimerComponent from "../components/TimerComponent";

import crack1 from '../assets/images/cracked1.png'
import crack2 from '../assets/images/cracked2.png'
import crack0 from '../assets/images/cracked0.png'
import crack3 from '../assets/images/cracked3.png'
import crack4 from '../assets/images/cracked4.png'
import crack5 from '../assets/images/cracked5.png'

import bomb from '../assets/images/bomb.jpg'

import "./Screen.css"
import NewVersionChecker from "../components/newVersion";

var pjson = require('../../package.json');

const TIMER_MS = 250;

const screenObj = () => {
    return {
        position: { x: 0, y: 0 },
        delay: 10,
        id: 0,
        color: "white",
        imgId: 0
    }
}


const Screen: React.FC = () => {
    const [myText, setMyText] = useState("I am so Angry!");
    const [buttonText, setButtonText] = useState("I'M ANGRY!");
    const [labels, setLabels] = useState([{}]);
    const [cracks, setCracks] = useState([{}]);
    const [labelId, setLabelId] = useState(1);
    const [crackId, setCrackId] = useState(1);
    const [crackCnt, setCrackCnt] = useState(1);
    const [thisSecsPresses, setThisSecsPresses] = useState(0);
    const [lastSecsPresses, setLastSecsPresses] = useState(0);
    const [lastSec, setLastSec] = useState(Date.now());
    const [lastTimer, setLastTimer] = useState(Date.now());
    const [square, setSquare] = useState(true);
    const [showSettings, setShowSettings] = useState(false);
    const [BOMB_BASIS, setBombBasis] = useState(7);
    const [PRESS_LIMIT, setPressLimit] = useState(5);
    const [CRACK_DECAY, setCrackDecay] = useState(0.5);
    const [settingsChanged, setSettingsChanged] = useState(false);
    const [settingsLoaded, setSettingsLoaded] = useState(false);
    const [audioPlayed, setAudioPlayed] = useState(false);
    const [bombPlayer, setBombPlayer] = useState(null as any);
    const [crack0Player, setCrack0Player] = useState(null as any);
    const [crack1Player, setCrack1Player] = useState(null as any);
    const [crack2Player, setCrack2Player] = useState(null as any);

    const crackImages = [crack0, crack1, crack2, crack3, crack4, crack5];
    const colors = ["white", "red", "green", "blue", "teal", "orange", "purple", "magenta", "cyan", "black"];
    const navigator = global.navigator;

    async function initStorage() {
        const store = new Storage();
        await store.create();
        store.forEach((value, key) => {
            console.log(key + ":" + value);

            switch (key) {
                case "angryText": setMyText(value); break;
                case "bombBasis": setBombBasis(value); break;
                case "pressLimit": setPressLimit(value); break;
                case "crackDecay": setCrackDecay(value); break;
                case "square": setSquare(value); break;
                case "button": setButtonText(value); break;
            }
        });

        console.log("Loaded settings");
    }

    async function checkSaveSettings() {
        if (!settingsChanged) return;
        setSettingsChanged(false);
        const store = new Storage();
        await store.create();
        store.set("angryText", myText);
        store.set("bombBasis", BOMB_BASIS);
        store.set("pressLimit", PRESS_LIMIT);
        store.set("crackDecay", CRACK_DECAY);
        store.set("square", square);
        store.set("button", buttonText);
        console.log("Saved settings");
        store.forEach((value, key) => {
            console.log(key + ":" + value);
        });

    }

    if (!settingsLoaded) {
        setSettingsLoaded(true);
        initStorage();
    } else {
        checkSaveSettings()
    }

    /* Ensure the timer keeps gettingn called if button presses are fast */
    function checkTimer() {
        if (Date.now() - lastTimer > TIMER_MS) {
            onTimer();
        }
    }

    function onTimer() {
        //console.log('onTimer');
        // Don't fore more often than the periodic need
        const now = Date.now();
        if (now - lastTimer < TIMER_MS) {
            return;
        }
        setLastTimer(Date.now());


        const newLabels: any[] = [];
        const newCracks: any[] = [];

        // Count down the timers
        labels.forEach((l: any) => {
            if (l.delay > 1) {
                l.delay = l.delay - CRACK_DECAY * 2;
                newLabels.push(l);
                //console.log(JSON.stringify(l));
            }
        });

        cracks.forEach((c: any) => {
            if (c.delay > 1) {
                c.delay = c.delay - CRACK_DECAY;
                newCracks.push(c);
            }
        });

        // Save the timers
        setLabels(newLabels);
        setCracks(newCracks);

        // 1 sec passed?  reset crack counters
        if (now - lastSec > 1000) {
            setLastSec(Date.now());
            setLastSecsPresses(thisSecsPresses);
            setThisSecsPresses(0);
        }

        // Reset coutners to 0 if a crack is shown
        if (newCracks.length > 0) {
            setThisSecsPresses(0);
            setLastSecsPresses(0);
        }
    }

    function getScreenSize() {
        var res = { "width": 630, "height": 460 };

        if (document.body) {
            if (document.body.offsetWidth) res["width"] = document.body.offsetWidth;
            if (document.body.offsetHeight) res["height"] = document.body.offsetHeight;
        }

        if (window.innerWidth) res["width"] = window.innerWidth;
        if (window.innerHeight) res["height"] = window.innerHeight;

        return res;
    }

    function getRandomPosition(): { x: number, y: number } {
        const res = getScreenSize();
        return { x: Math.floor(Math.random() * res.width), y: Math.floor(Math.random() * res.height) };
    }

    // Add a new crack
    function addCrack() {
        console.debug("Add crack");
        if (cracks.length > 0) return; // Only 1 crack at a time

        const newCrack = screenObj();
        newCrack.id = crackId;
        setCrackId(crackId + 1);

        // Select a random crack
        newCrack.imgId = Math.floor(Math.random() * crackImages.length);

        // Should this one be a bomb?
        if (crackCnt % BOMB_BASIS === 0)
            newCrack.imgId = -1;

        const newCracks: any[] = [];
        cracks.forEach((l) => {
            newCracks.push(l);
        });
        //console.log("new crack: " + JSON.stringify(newCrack));
        setCracks(newCracks);
        setCrackCnt(crackCnt + 1);
        setAudioPlayed(false);  // Trigger audio to play
        newCracks.push(newCrack);

    }

    // Add a label to the list of labels to draw
    function addLabel() {
        const newLabel = screenObj();
        newLabel.id = labelId;
        setLabelId(labelId + 1);

        newLabel.position = getRandomPosition();
        newLabel.color = colors[Math.floor(Math.random() * colors.length)];

        const newLabels: any[] = [];
        newLabels.pop();
        labels.forEach((l) => {
            newLabels.push(l);
        });
        //console.log("new label: " + JSON.stringify(newLabel));
        newLabels.push(newLabel);
        setLabels(newLabels);
    }

    // Draw the labels
    function doLabels() {
        return (
            labels.map((l: any) => {
                //console.log(JSON.stringify(l));
                if (l && l.id) {
                    return (
                        <div style={{
                            position: "absolute",
                            top: l.position.y,
                            left: l.position.x,
                            color: l.color,
                            opacity: l.delay * 10 + "%",
                            fontSize: 28
                        }}>{myText}</div>
                    )
                }
                // If no labels, return an empty object
                return (<></>)
            })
        )
    }

    // Draw the crack
    function doCracks() {
        return (
            cracks.map((c: any) => {
                //console.log(JSON.stringify(c));
                if (c && c?.id) {

                    // Play the audio if not played already
                    if (!audioPlayed) {
                        setAudioPlayed(true);
                        if (c.imgId >= 0) {
                            const a = Math.floor(Math.random() * 3);
                            switch (a) {
                                case 0: if (crack0Player) crack0Player.play(); break;
                                case 1: if (crack1Player) crack1Player.play(); break;
                                case 2: if (crack2Player) crack2Player.play(); break;
                                default: if (crack2Player) crack2Player.play(); break;
                            }
                            if (navigator.vibrate) navigator.vibrate(200);
                        } else {
                            if (bombPlayer) bombPlayer.play();
                            if (navigator.vibrate) navigator.vibrate(400);
                        }
                    }

                    return (
                        <img alt="crack" src={c.imgId > -1 ? crackImages[c.imgId] : bomb}
                            style={{
                                position: "absolute",
                                height: "100%",
                                width: "100%",
                                objectFit: 'cover',
                                opacity: c?.delay * 10 + "%"
                            }} />
                    )
                }
                // If no cracks, return an empty object
                return (<></>)
            })
        )
    }

    // On press - increment the nbumber of presses and decide if its time to creae a crack
    function onPress() {
        if (thisSecsPresses + lastSecsPresses + 1 > PRESS_LIMIT) {
            addCrack();
            setLastSecsPresses(0);
            setThisSecsPresses(0);
        } else {
            // Otherwise just add our label
            setThisSecsPresses(thisSecsPresses + 1);
            addLabel();
        }
        // Call the timer update as well
        checkTimer();

    }

    return (
        <IonPage >
            <IonContent>
                <NewVersionChecker />
                
                {/* Settings fab button */}
                <IonFab horizontal="start" vertical="top" slot="fixed">
                    <IonFabButton color="dark" onClick={() => setShowSettings(true)}>
                        <IonIcon icon={settings} />
                    </IonFabButton>
                </IonFab>

                {/* Modal for editing settigns 
                    Each itme sets the setting directly
                    as well as settingsChanged state param which triggers save on next redraw
                */}
                <IonModal isOpen={showSettings} onDidDismiss={() => setShowSettings(false)}>
                    <IonCard>

                        <IonCardContent>
                            <IonItem>
                                <IonButton color="primary" onClick={() => setShowSettings(false)}>
                                    <IonIcon icon={close} slot="icon-only" />
                                </IonButton>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="fixed">Angry text</IonLabel>
                                <IonInput name="text" value={myText} onIonChange={(e: any) => {
                                    setMyText(e.target.value);
                                    setSettingsChanged(true)
                                }} />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="fixed">Buttonn text</IonLabel>
                                <IonInput name="text" value={buttonText} onIonChange={(e: any) => {
                                    setButtonText(e.target.value);
                                    setSettingsChanged(true)
                                }} />
                            </IonItem>
                            <IonItem>
                                <IonLabel slot="start">Square Button</IonLabel>
                                <IonToggle checked={square} onIonChange={(e: any) => {
                                    setSquare(e.detail.checked);
                                    setSettingsChanged(true);
                                }} />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="fixed" slot="start">
                                    Cracks
                                </IonLabel>
                                <IonRange min={0} max={10} step={1} snaps value={10 - PRESS_LIMIT} onIonChange={(e: any) => {
                                    setPressLimit(10 - e.target.value);
                                    setSettingsChanged(true)
                                }} />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="fixed" slot="start">
                                    Bombs
                                </IonLabel>
                                <IonRange min={0} max={7} step={1} snaps value={10 - BOMB_BASIS} onIonChange={(e: any) => {
                                    setBombBasis(10 - e.target.value);
                                    setSettingsChanged(true)
                                }} />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="fixed" slot="start">
                                    Fade
                                </IonLabel>
                                <IonRange min={0} max={10} step={1} snaps value={CRACK_DECAY * 4} onIonChange={(e: any) => {
                                    setCrackDecay((e.target.value) / 4);
                                    setSettingsChanged(true)
                                }} />
                            </IonItem>

                        </IonCardContent>
                    </IonCard>
                    <IonCard>
                        <IonCardContent>
                            Copyright &copy; 2021 Seth Osher &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="https://angrier.me">https://angrier.me</a> {pjson.version}
                            <hr />
                            Sound effects obtained from <a href="https://www.zapsplat.com">https://www.zapsplat.com</a>
                        </IonCardContent>
                    </IonCard>

                </IonModal>

                <div style={{
                    backgroundColor: '#C0A0A0',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    width: '100%',

                }}>
                    {/* Our timer and audio players */}
                    <TimerComponent period={TIMER_MS} callback={onTimer} />
                    <audio src="boom.mp3" ref={ref => setBombPlayer(ref)} />
                    <audio src="crack0.mp3" ref={ref => setCrack0Player(ref)} />
                    <audio src="crack1.mp3" ref={ref => setCrack1Player(ref)} />
                    <audio src="crack2.mp3" ref={ref => setCrack2Player(ref)} />


                    {/* Put out the cracks and text */}
                    {doLabels()}
                    {doCracks()}

                    {/* The grid to center our button */}
                    <IonGrid style={{ height: "100%" }}>
                        <IonRow className="ion-align-items-center" style={{ height: "100%" }}>
                            <IonCol />
                            <IonCol >
                                {square ?
                                    <IonButton style={{ width: 200, height: 200, borderRadious: 100 }} size="large" shape="round"
                                        onClick={() => onPress()}
                                        color="danger" fill="solid">
                                        {buttonText}
                                    </IonButton>
                                    :
                                    <IonFabButton style={{ width: 200, height: 200 }}
                                        onClick={() => onPress()}
                                        color="danger">
                                        <h2>I'M ANGRY!</h2>
                                    </IonFabButton>
                                }
                            </IonCol>
                            <IonCol />
                        </IonRow>
                    </IonGrid>
                </div>

            </IonContent >
        </IonPage >
    )
}

export default Screen;
