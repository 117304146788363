import React, { Component } from 'react';

class TimeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { time: Date.now()};
  }
  render(){
    return(
        <>
        </>
    );
  }

  onEvent()
  {
    this.setState({ time: Date.now() })
    //console.debug(this.state.time);
    if(this.props.callback) {
        this.props.callback();
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => this.onEvent(), this.props.period > 100 ? this.props.period : 100);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  

}

export default TimeComponent;
