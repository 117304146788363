import { IonToast } from '@ionic/react';
import React, { FC, useEffect } from 'react';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

const NewVersionChecker: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload(true);
  };

  return (
    <IonToast
      isOpen={showReload}
      message="A new version is available!"
      onDidDismiss={() => setShowReload(false)}
      position="top"
      buttons={[
          {
            side: 'end',
            text: 'Update',
            handler: () => {reloadPage()}
          },
          {
              text: 'Cancel',
              role: 'Cancel',
              handler: () => {setShowReload(false)}
          }

      ]}
    />
  );
}

export default NewVersionChecker;
